/**
 * Home page additional styles
 */

.tagline {
  padding: 0 1em .8em 1em;
  text-align: center;

  font-size: 3rem;
  font-size: 3.5vh;
  line-height: 1.1;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, .3);
}
.tagline > span {
  font-weight: 200;
}
.tagline > strong {
  font-weight: 400;
}
.tagline > b {
  font-weight: 500;
  font-size: 1.3em;
  line-height: 1.5;
}

.logo {
  width: auto;
  height: 26vh;
}
.qr-code {
  width: auto;
  height: 16vh;
}

.work-in-progress {
  padding-top: .5em;
}

/**
 * A nice rainbow gradient
 */

body {

  background: linear-gradient(
    -45deg,
    rgba(255, 0,   0,   1) 0%,
    rgba(255, 154, 0,   1) 10%,
    rgba(208, 222, 33,  1) 20%,
    rgba(79,  220, 74,  1) 30%,
    rgba(63,  218, 216, 1) 40%,
    rgba(47,  201, 226, 1) 50%,
    rgba(28,  127, 238, 1) 60%,
    rgba(95,  21,  242, 1) 70%,
    rgba(186, 12,  248, 1) 80%,
    rgba(251, 7,   217, 1) 90%,
    rgba(255, 0,   0,   1) 100%);

  /* TODO: use autoprefixer or remove */
  background: -moz-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%);
  background: -webkit-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%);

  background-repeat: no-repeat;

}
